import PropTypes from "prop-types";
import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { connect } from "react-redux";
import MenuCart from "./sub-components/MenuCart";
import { deleteFromCart } from "../../redux/actions/cartActions";
import { signOut } from "../../redux/actions/userAction";

const IconGroup = ({
  currency,
  cartData,
  wishlistData,
  compareData,
  deleteFromCart,
  iconWhiteClass,
  signOut,
  user,
  search_bar_change,
  userLog,
  sellerLog,
  adminLog
}) => {
  const handleClick = (e) => {
    e.currentTarget.nextSibling.classList.toggle("active");
  };
  const [search_bar, setSearchBar] = useState("");
  const triggerMobileMenu = () => {
    const offcanvasMobileMenu = document.querySelector(
      "#offcanvas-mobile-menu"
    );
    offcanvasMobileMenu.classList.add("active");
  };

  useEffect(() => {
    console.log('here coming', userLog, user);
    // const x = Object.keys(user).length;
    // console.log({ user, x });
  }, [user]);

  const logout = (e) => {
    e.preventDefault();
    localStorage.removeItem("token_user");
    signOut();
    if (
      localStorage.getItem("btap_token") ||
      localStorage.getItem("token_assignee") ||
      sessionStorage.getItem("token_admin")
    ) {
      localStorage.removeItem("btap_token");
      localStorage.removeItem("token_assignee");
      sessionStorage.removeItem("token_admin");
    }
    window.location.reload();
  };
  const handleTextChange = (e) => {
    setSearchBar(e.value);
  };

  const searchNow = (e) => {
    e.preventDefault();
    search_bar_change({ name: "search_bar", value: search_bar });
  };

  return (
    <div
      className={`header-right-wrap ${
        iconWhiteClass ? iconWhiteClass : ""
      } mt-2`}
      style={{ marginTop: 0 }}
    >
      <div className="same-style account-setting d-lg-block">
        <button
          className="account-setting-active p-0"
          onClick={(e) => handleClick(e)}
          ss
        >
          <i className="pe-7s-user-female" />
        </button>
        <div className="account-dropdown">
          {user && Object.keys(user).length ? (
            <ul>
              <li>
                <Link to={"/my-account"}>My Account</Link>
              </li>
              <li>
                <Link to={`/buyer/order/list/${user.id}`}>My Orders</Link>
              </li>
              <li>
                <Link to={`/buyer/messages`}>Messages</Link>
              </li>
              <li>
                <Link to={""} onClick={logout}>
                  Logout
                </Link>
              </li>
            </ul>
          ) : null}
          {localStorage.getItem("btap_token") || localStorage.getItem("token_assignee") || sessionStorage.getItem("token_admin") ? (
            <ul>
              <li>
                <Link to={""} onClick={logout}>
                  logout
                </Link>
              </li>
            </ul>
          ) : null}
          {(!user || Object.keys(user).length <= 0) &&
          !sessionStorage.getItem("token_admin") &&
          !localStorage.getItem("btap_token") &&
          !localStorage.getItem("token_assignee") ? (
            <ul>
              <li>
                <Link to={"/buyer/login"}>Login</Link>
              </li>
            </ul>
          ) : null}
        </div>
      </div>
      {/* <div className="same-style header-compare">
        <Link to={"/compare"}>
          <i className="pe-7s-shuffle" />
          <span className="count-style">
            {compareData && compareData.length ? compareData.length : 0}
          </span>
        </Link>
      </div> */}
      {userLog ? <div className="same-style header-wishlist">
        <Link to={"/wishlist"}>
          <i className="pe-7s-like" />
          <span className="count-style">
            {wishlistData && wishlistData.length ? wishlistData.length : 0}
          </span>
        </Link>
      </div> : null}
      {userLog ? <div className="same-style cart-wrap d-none d-lg-block">
        <button className="icon-cart" onClick={(e) => handleClick(e)}>
          <i className="pe-7s-shopbag" />
          <span className="count-style">
            {cartData && cartData.length ? cartData.length : 0}
          </span>
        </button>
        {/* menu cart */}
        <MenuCart
          cartData={cartData}
          currency={currency}
          deleteFromCart={deleteFromCart}
        />
      </div> : null}
      {userLog ? <div className="same-style cart-wrap d-block d-lg-none">
        <Link className="icon-cart" to={"/cart"}>
          <i className="pe-7s-shopbag" />
          <span className="count-style">
            {cartData && cartData.length ? cartData.length : 0}
          </span>
        </Link>
      </div> : null}
      {/* <div className="same-style mobile-off-canvas d-block d-lg-none">
        <button
          className="mobile-aside-button"
          onClick={() => triggerMobileMenu()}
        >
          <i className="pe-7s-menu" />
        </button>
      </div> */}
    </div>
  );
};

IconGroup.propTypes = {
  cartData: PropTypes.array,
  compareData: PropTypes.array,
  currency: PropTypes.object,
  iconWhiteClass: PropTypes.string,
  deleteFromCart: PropTypes.func,
  wishlistData: PropTypes.array,
};

const mapStateToProps = (state) => {
  return {
    currency: state.currencyData,
    cartData: state.cartData,
    wishlistData: state.wishlistData,
    compareData: state.compareData,
    user: state.userDetails.user || null,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    deleteFromCart: (item, addToast) => {
      dispatch(deleteFromCart(item, addToast));
    },
    signOut: () => {
      dispatch(signOut());
    },
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(IconGroup);
