import PropTypes from "prop-types";
import React, { useState, useEffect } from "react";
import {
  getIndividualCategories,
  getIndividualTags,
  getIndividualColors,
  getProductsIndividualSizes,
} from "../../helpers/product";
import ShopSearch from "../../components/product/ShopSearch";
import ShopCategories from "../../components/product/ShopCategories";
import ShopColor from "../../components/product/ShopColor";
import ShopSize from "../../components/product/ShopSize";
import ShopTag from "../../components/product/ShopTag";
import "./reworked_product_styles.css";
import FilterWidget from "../../components/reworkedComponents/FilterWidget";
const ShopSidebar = ({
  products,
  getSortParams,
  sideSpaceClass,
  categories,
  getFilterSortParams,
  searchPlaceholder,
}) => {
  const uniqueCategories = getIndividualCategories(products);
  const uniqueColors = getIndividualColors(products);
  const uniqueSizes = getProductsIndividualSizes(products);
  const uniqueTags = getIndividualTags(products);
  const [showSearch, setShowSearch] = useState(false);
  const [isMobile, setIsMobile] = useState(false);

  const handleShowSearch = (val) => {
    setShowSearch(val);
  }
  
  useEffect(() => {
    setIsMobile(window.innerWidth < 768);
    window.addEventListener('resize', () => { setIsMobile(window.innerWidth < 768) });
  }, []);

  return (
    <div className="top-bar-container" style={{ flexDirection: isMobile && showSearch ? 'column' : 'row' }}>
      {/* filter by categories */}

      <div style={{ display: "flex", alignItems: "center" }}>
        <ShopCategories categories={categories} getSortParams={getSortParams} />
        <FilterWidget
          categories={[
            { name: "Price - High to Low", value: "priceHighToLow" },
            { name: "Price - Low to High", value: "priceLowToHigh" },
          ]}
          getFilterSortParams={getFilterSortParams}
          title="Price"
        />
        {/* <ShopCategories categories={categories} getSortParams={getSortParams} /> */}
      </div>
      {/* shop search */}
      <ShopSearch
        getSearch={getSortParams}
        searchPlaceholder={searchPlaceholder}
        handleShowSearch={handleShowSearch}
      />

      {/* filter by color */}
      {/* <ShopColor colors={uniqueColors} getSortParams={getSortParams} /> */}

      {/* filter by size */}
      {/* <ShopSize sizes={uniqueSizes} getSortParams={getSortParams} /> */}

      {/* filter by tag */}
      {/* <ShopTag tags={uniqueTags} getSortParams={getSortParams} /> */}
    </div>
  );
};

ShopSidebar.propTypes = {
  getSortParams: PropTypes.func,
  products: PropTypes.array,
  sideSpaceClass: PropTypes.string,
};

export default ShopSidebar;
