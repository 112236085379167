import React, { useState } from "react";

const MobileSearch = ({ search_bar_change, closeMobileMenu }) => {

  const [search_bar, setSearchBar] = useState('');

  const handleTextChange = (e) => {
    setSearchBar(e.value)
  }

  const searchNow = (e) => {
    e.preventDefault();
    search_bar_change({name: 'search_bar', value: search_bar });
    closeMobileMenu();
  }

  return (
    <div className="offcanvas-mobile-search-area">
      { search_bar_change ? <form onSubmit={searchNow}>
          <input               
            type="text" 
            placeholder="Search By Brands"
            name="search_bar" 
            value={search_bar}
            onChange={(e) => handleTextChange(e.target) } 
          />
          <button type="submit" onClick={searchNow}>
            <i className="fa fa-search" />
          </button>
        </form> 
        : null
      }
    </div>
  );
};

export default MobileSearch;
