import React, { useEffect } from "react";
import { Accordion, Button, Card } from "react-bootstrap";
import { AiOutlineRight } from "react-icons/ai";
import { AiOutlineDown } from "react-icons/ai";
import { useHistory } from "react-router-dom";
import "./headerStyling.css";

const MobileMenuSubAccordion = ({
  elem,
  i,
  currentEventKeySubCats,
  setCurrentEventKeySubCats,
  setShowCollapseMenu,
  mainCategory,
}) => {
  const history = useHistory();

  return (
    <Card
      key={i}
      className="text-left"
      style={{ backgroundColor: "transparent" }}
    >
      <Card.Header
        style={{
          display: "flex",
          alignItems: "center",
          justifyContent: "space-between",
          //   backgroundColor: "rgba(0, 0, 0, 0.88)",
          //   backdropFilter: " blur(5px)",
        }}
      >
        <p
          className="collapse-item-sub-menu"
          onClick={() => {
            history.push(`/category/${mainCategory}/${elem.name}/`);
            setShowCollapseMenu(false);
          }}
        >
          {elem.name}
        </p>
        <Accordion.Toggle
          as={Button}
          eventKey={i}
          onClick={() => {
            if (currentEventKeySubCats === i) {
              setCurrentEventKeySubCats(null);
            } else {
              setCurrentEventKeySubCats(i);
            }
          }}
          style={{ background: "none", border: "none" }}
        >
          {currentEventKeySubCats === i ? (
            <AiOutlineDown
              color="white"
              size={20}
              style={{ cursor: "pointer", zIndex: 200 }}
              onClick={() => console.log({ elem })}
            />
          ) : (
            <AiOutlineRight
              color="white"
              size={20}
              style={{ cursor: "pointer", zIndex: 200 }}
              onClick={() => console.log({ elem })}
            />
          )}
        </Accordion.Toggle>
      </Card.Header>

      {elem?.secondry_sub_categories?.map((e) => (
        <Accordion.Collapse eventKey={i}>
          <Card.Body>
            <pre
              style={{
                textAlign: "left",
                color: "#858688",
                fontSize: 16,
                fontWeight: 400,
                fontFamily: "lato",
                overflowX: "hidden",
                marginLeft: 20,
                marginBottom: 0,
              }}
              onClick={() => {
                history.push(
                  `/category/${mainCategory}/${elem.name}/${e.name}/`
                );
                setShowCollapseMenu(false);
              }}
            >
              {e.name}
            </pre>
          </Card.Body>
        </Accordion.Collapse>
      ))}
    </Card>
  );
};

export default MobileMenuSubAccordion;
