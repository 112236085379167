import uuid from "uuid/v4";
import {
  ADD_TO_CART,
  DECREASE_QUANTITY,
  DELETE_FROM_CART,
  DELETE_ALL_FROM_CART
} from "../actions/cartActions";

const initState = [];

const cartReducer = (state = initState, action) => {
  const cartItems = state,
    product = action.payload;
  if (action.type === ADD_TO_CART) {
    // for non variant products
    if (product.variation === undefined) {
      
      const cartItem = cartItems.filter(item => item.id === product.id);
     
      if (cartItem === undefined || cartItem.length <= 0) {
        
        const newProduct = Object.assign({}, product);
        const selectedVariant = newProduct.currentVariants && newProduct.currentVariants.length ? newProduct.currentVariants : [];
        delete newProduct.currentVariants;

        return [
          ...cartItems,
          {
            ...newProduct,
            stock: newProduct.stock ? newProduct.stock : 1,
            cartItemId: uuid(),
            selectedProductVariant: selectedVariant
          }
        ];
      } else {
        if (product.currentVariants && product.currentVariants.length && product.product_varients && product.product_varients.length) {
          let newCartItems = cartItem;
          let globalCheck = [];
          let globalCheck1 = [];
          let selectedItem = null;
          const currentVariants = product.currentVariants;
          for (let i = 0; i < newCartItems.length; i++) {
            const singleItem = newCartItems[i];
            if (singleItem.selectedProductVariant && singleItem.selectedProductVariant.length) {
              globalCheck.push(true);
              let check = [];
              singleItem.selectedProductVariant.forEach(elem => {
                let filter = currentVariants.filter(x => x.id == elem.id && x.type.toLowerCase() == elem.type.toLowerCase() && x.option.toLowerCase() == elem.option.toLowerCase());
                if (filter && filter.length) {
                  check.push(true);
                } else {
                  check.push(false);
                }
              });
              if (check.includes(false)) {
                selectedItem = null;
                globalCheck1.push(false);
              } else {
                selectedItem = null;
                // singleItem.stock = product.stock ? singleItem.stock + product.stock : singleItem.stock + 1;
                globalCheck1 = [];
                selectedItem = Object.assign({}, singleItem);
                globalCheck1.push(true);
                console.log('loop break: ');
                break;
              }
            } else {
              globalCheck.push(false);
            }
          }

          if (globalCheck1.includes(true) && selectedItem) {
            console.log('here: ', globalCheck1);
            return cartItems.map(item =>
              item.cartItemId === selectedItem.cartItemId
                ? {
                    ...item,
                    stock: product.stock
                      ? item.stock + product.stock
                      : item.stock + 1
                  }
                : item
            );
          } else {
            console.log('there: ', globalCheck1);
           
            const newProduct = Object.assign({}, product);
            const selectedVariant = newProduct.currentVariants;
            delete newProduct.currentVariants;
            return [
              ...cartItems,
              {
                ...newProduct,
                stock: product.stock ? product.stock : 1,
                cartItemId: uuid(),
                selectedProductVariant: selectedVariant
              }
            ];
          }
        } else {
          console.log('coming');
          return cartItems.map(item =>
            item.cartItemId === cartItem[0].cartItemId
              ? {
                  ...item,
                  stock: product.stock
                    ? item.stock + product.stock
                    : item.stock + 1
                }
              : item
          );
        }
    } 
    // for variant products
    } else {
      const cartItem = cartItems.filter(
        item =>
          item.id === product.id &&
          product.selectedProductColor &&
          product.selectedProductColor === item.selectedProductColor &&
          product.selectedProductSize &&
          product.selectedProductSize === item.selectedProductSize &&
          (product.cartItemId ? product.cartItemId === item.cartItemId : true)
      )[0];

      if (cartItem === undefined) {
        return [
          ...cartItems,
          {
            ...product,
            stock: product.stock ? product.stock : 1,
            cartItemId: uuid()
          }
        ];
      } else if (
        cartItem !== undefined &&
        (cartItem.selectedProductColor !== product.selectedProductColor ||
          cartItem.selectedProductSize !== product.selectedProductSize)
      ) {
        return [
          ...cartItems,
          {
            ...product,
            stock: product.stock ? product.stock : 1,
            cartItemId: uuid()
          }
        ];
      } 
      else {
        return cartItems.map(item =>
          item.cartItemId === cartItem.cartItemId
            ? {
                ...item,
                stock: product.stock
                  ? item.stock + product.stock
                  : item.stock + 1,
                selectedProductColor: product.selectedProductColor,
                selectedProductSize: product.selectedProductSize
              }
            : item
        );
      }
    }
  }

  if (action.type === DECREASE_QUANTITY) {
    if (product.stock === 1) {
      const remainingItems = (cartItems, product) =>
        cartItems.filter(
          cartItem => cartItem.cartItemId !== product.cartItemId
        );
      return remainingItems(cartItems, product);
    } else {
      return cartItems.map(item =>
        item.cartItemId === product.cartItemId
          ? { ...item, stock: item.stock - 1 }
          : item
      );
    }
  }

  if (action.type === DELETE_FROM_CART) {
    const remainingItems = (cartItems, product) =>
      cartItems.filter(cartItem => cartItem.cartItemId !== product.cartItemId);
    return remainingItems(cartItems, product);
  }

  if (action.type === DELETE_ALL_FROM_CART) {
    return cartItems.filter(item => {
      return false;
    });
  }

  return state;
};

export default cartReducer;
