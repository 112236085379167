import React, { useEffect, useState } from "react";
import { Button } from "react-bootstrap";
import {
  pageLinks,
  supportLinks,
  usefulLinks,
} from "../../../helpers/Constants";
import "./footer.css";
import FooterCard from "./FooterCard";
import { GrFacebook } from "react-icons/gr";
import { GrTwitter } from "react-icons/gr";
import { GrInstagram } from "react-icons/gr";
import { GrYoutube } from "react-icons/gr";
import { useWindowDims } from "../../../helpers/useDimensions";

const FooterContainer = () => {
  const { width } = useWindowDims();
  const [email, setEmail] = useState("");
  useEffect(() => {
    console.log('width f: ', width);
  }, [width]);
  return (
    <div
      style={{
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        backgroundColor: "#F8F8F8",
      }}
    >
      <div className="footer-container">
        <div className="footer-sub-container">
          <FooterCard title="PAGES" links={pageLinks} />
          <FooterCard title="SUPPORT" links={supportLinks} />
        </div>
        <FooterCard title="USEFUL LINKS" links={usefulLinks} />
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            alignItems: "flex-start",
            justifyContent: "center",
            padding: 10,
          }}
        >
          <p style={{ fontSize: 14, color: "#343538", fontWeight: 600 }}>
            SUBSCRIBE
          </p>
          <p
            style={{
              fontSize: 14,
              color: "#A3A5AD",
              fontWeight: 400,
              fontFamily: "lato",
              marginBottom: 5,
              textAlign: "left",
            }}
          >
            Get E-mail updates about our latest special offers and blogs.
          </p>

          <input
            type="email"
            className="form-control"
            placeholder="Enter your Email address"
            value={email}
            onChange={(e) => setEmail(e.target.value)}
          />
          <Button
            href={"/seller/apply"}
            style={{
              // background: "none",
              // color: "white",
              fontSize: 15,
              fontFamily: "lato",
              // borderColor: "black",
              minWidth: 200,
            }}
            //   class="btn btn-secondary btn-lg disabled"
            size="lg"
            className="mr-10 mt-10 mb-10 custom-width btn-secondary"
            disabled={email === "" ? true : false}
          >
            Subscribe
          </Button>
        </div>
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            alignItems: "flex-start",
            justifyContent: "center",
            padding: 10,
          }}
        >
          <p style={{ fontSize: 14, color: "#343538", fontWeight: 600 }}>
            FOLLOW US
          </p>

          <div
            style={{
              display: "flex",
              flexDirection: "row",
              alignItems: "center",
              justifyContent: "space-evenly",
              width: "100%",
            }}
          >
            <GrFacebook
              color="#C8C8C8"
              size={16}
              style={{ marginRight: 10, cursor: "pointer" }}
              onClick={() => window.open("//www.facebook.com")}
            />
            <GrTwitter
              color="#C8C8C8"
              size={16}
              style={{ marginLeft: 10, marginRight: 10, cursor: "pointer" }}
              onClick={() => window.open("//www.twitter.com")}
            />
            <GrInstagram
              color="#C8C8C8"
              size={16}
              style={{ marginLeft: 10, marginRight: 10, cursor: "pointer" }}
              onClick={() => window.open("//www.instagram.com")}
            />
            <GrYoutube
              color="#C8C8C8"
              size={16}
              style={{ marginLeft: 10, cursor: "pointer" }}
              onClick={() => window.open("//www.youtube.com")}
            />
          </div>
        </div>
      </div>

      <div
        style={{
          alignSelf: "center",
          width: "80%",
          display: "flex",
          alignItems: "center",
          justifyContent: "space-between",

          borderTop: "2px solid #ededed",
          padding: 20,
        }}
      >
        {width > 770 && (
          <p style={{ fontSize: 20, color: "#343538", fontWeight: 600 }}>
            <img src="/assets/img/stoqli_logo.png" alt="logo" />
          </p>
        )}

        <p style={{ fontSize: 14, color: "#A3A5AD", fontWeight: 400 }}>
          © 2022 Stoqli. All Rights Reserved
        </p>
      </div>
    </div>
  );
};

export default FooterContainer;
