import React from "react";
import { Link } from "react-router-dom";

const FooterCard = ({ title, links }) => {
  return (
    <div
      style={{
        display: "flex",
        flexDirection: "column",
        alignItems: "flex-start",
        justifyContent: "center",
        padding: 10,
      }}
    >
      <p style={{ fontSize: 14, color: "#343538", fontWeight: 600 }}>{title}</p>

      {links?.map((e, index) => (
        <Link
          to={e.linkTo}
          key={index}
          style={{
            fontSize: 14,
            color: "#A3A5AD",
            fontWeight: 400,
            fontFamily: "lato",
            marginBottom: 5,
            textAlign: "left",
          }}
        >
          {e.text}
        </Link>
      ))}
    </div>
  );
};

export default FooterCard;
