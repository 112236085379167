import Axios from "axios";
import React, { Fragment, useEffect, useState } from "react";
import { Card, Col, Container, Row } from "react-bootstrap";
import { MetaTags } from "react-meta-tags";
import { useLocation } from "react-router-dom";
import TagCard from "../../components/reworkedComponents/newTag/TagCard";
import ShopByCategoryCards from "../../components/reworkedComponents/shopBY/ShopByCategoryCards";
import config from "../../config";
import LayoutOne from "../../layouts/LayoutOne";
import ShopProducts from "../../wrappers/product/ShopProducts";
import ShopSidebar from "../../wrappers/product/ShopSidebar";
import ShopTopbar from "../../wrappers/product/ShopTopbar";
import { Link } from "react-router-dom";
import "../../components/reworkedComponents/reworkedProducts/product.css";
import SkeltonCard from "../helper/SkeltonCard";
import { BsArrowRightShort } from "react-icons/bs";

const ProductsByTag = () => {
  const location = useLocation();
  const [storeProducts, setStoreProducts] = useState([]);
  const [sortType, setSortType] = useState("");
  const [sortValue, setSortValue] = useState("");
  const [filterSortType, setFilterSortType] = useState("");
  const [filterSortValue, setFilterSortValue] = useState("");
  const [layout, setLayout] = useState("grid three-column");
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    console.log({ location }); // result: '/secondpage'ue'
  }, [location]);

  useEffect(() => {
    getBrandsData();
  }, []);

  useEffect(() => {
    console.log({ storeProducts });
  }, [storeProducts]);
  
  const getBrandsData = async () => {
    setIsLoading(true);
    const brandsDataResponse = await Axios.get(
      `${config.prod}/api/brands/list`
    );

    let allBrands = brandsDataResponse.data.data;
    let allBrandsWithTag = getAllStoreIds(allBrands);
    console.log('all brands: ', allBrandsWithTag);
    setStoreProducts(allBrandsWithTag);
    setIsLoading(false);
  };

  const getAllStoreIds = (brands) => {
    let allStoreIds = [];
    brands.forEach((element) => {
      if (element?.stores_setting) {
        JSON.parse(element.stores_setting.tags).forEach((e) => {
          if (e == location.state.tag) {
            allStoreIds.push(element);
          }
        });
      }
    });
    let uniqueArray = allStoreIds.filter(function (item, pos) {
      return allStoreIds.indexOf(item) == pos;
    });

    return uniqueArray;
  };
  const getSortParams = (sortType, sortValue) => {
    console.log(sortType, sortValue);
    setSortType(sortType);
    setSortValue(sortValue);
  };
  const getFilterSortParams = (sortType, sortValue) => {
    setFilterSortType(sortType);
    setFilterSortValue(sortValue);
  };
  const getLayout = (layout) => {
    setLayout(layout);
  };
  return (
    <Fragment>
      <MetaTags>
        <title>Stoqli | Store Page</title>
        <meta name="description" content="Store page of sttoqli." />
      </MetaTags>
      <LayoutOne
        headerContainerClass="container-fluid"
        headerPaddingClass="header-padding-1"
      >
        <div className="container">
          <Row>
            <Col>
              <React.Fragment>
                <Row>
                  <Col>
                    <Card style={{ border: 0 }}>
                      <Card.Body>
                        <Row>
                          <Col>
                            <h3>
                              <span
                                style={{
                                  fontWeight: 600,
                                  fontSize: 24,
                                }}
                              >{location.state.tag}</span>
                            </h3>
                          </Col>
                        </Row>
                      </Card.Body>
                    </Card>
                  </Col>
                </Row>
                { 
                  isLoading ? 
                  <Row>
                    <Col md={3}>
                      <SkeltonCard />
                    </Col>
                    <Col md={3}>
                      <SkeltonCard />
                    </Col>
                    <Col md={3}>
                      <SkeltonCard />
                    </Col>
                    <Col md={3}>
                      <SkeltonCard />
                    </Col>
                  </Row> : 
                  <Row>
                    {storeProducts.map((brand, index) => (
                      <div
                        key={index}
                        className={`col-xl-3 col-md-6 col-lg-4 col-sm-6 pt-3`}
                      >
                        <div
                          className="product-wrap product-container ml-0 mr-0"
                          //   style={{ maxWidth: width ? width * 0.1 : "10%" }}
                        >
                          <Link
                            style={{
                              padding: 10,
                              border: "1px solid #d9d9d9",
                              borderRadius: 5,
                              width: "100%",
                              cursor: "pointer",
                            }}
                            to={`/public/view/store/${brand.id}`}
                          >
                            <img
                              className="product-img"
                              style={{
                                width: "100%",
                                // height: 330,
                                objectFit: "cover",
                              }}
                              src={`${brand.logo}`}
                              alt=""
                              onError={(e) => {
                                e.target.src = `${config.prod}/assets/img/product/fashion/1.jpg`;
                              }}
                            />
                          </Link>

                          <div
                            style={{
                              width: "100%",
                              margin: 10,
                              paddingLeft: 20,
                              textAlign: "center",
                            }}
                          >
                            <a
                              style={{
                                fontSize: 14,
                                fontWeight: 400,
                                color: "#6699CC",
                                margin: 0,
                                fontFamily: "lato",
                              }}
                              href={"/public/view/store/" + brand.id}
                            >
                              {" "}
                              By {brand.name}
                            </a>
                            <p
                              style={{
                                fontSize: 14,
                                fontWeight: 400,
                                color: "#343538",
                              }}
                            >
                              {" "}
                              Minimum Order{" "}
                              {brand.stores_setting &&
                              brand.stores_setting.minimum_order
                                ? `R${brand.stores_setting.minimum_order}`
                                : `NA`}
                            </p>
                          </div>
                          <div
                            style={{
                              display: "flex",
                              alignItems: "center",
                              justifyContent: "center",
                              paddingTop: 10,
                              paddingBottom: 10,
                              borderBottom: "1px solid black",
                              alignSelf: "flex-start",
                              cursor: "pointer",
                              width: 'fit-content'
                            }}
                          >
                            <a
                              style={{
                                fontSize: 14,
                                color: "black",
                                margin: 0,
                              }}
                              href={"/public/view/store/" + brand.id}
                            >
                              View All Products
                            </a>
                            <BsArrowRightShort />
                          </div>
                        </div>
                      </div>
                    ))}
                  </Row>
                }
              </React.Fragment>
            </Col>
          </Row>
        </div>
        {/* <div className="shop-area pb-100 pt-50">
          <div className="container">
            <div className="row">
              <div className="col-12 pb-50">
                <h3>
                  {" "}
                  <span style={{ fontWeight: 600 }}>{location.state.tag}</span>
                </h3>
              </div>
            </div>
            <div className="col-lg-12 order-1 order-lg-2">
              <Row lg={4}>
                {storeProducts.map((e, index) => (
                  <Col style={{ height: 400 }}>
                    <TagCard key={index} data={e} />
                  </Col>
                ))}
              </Row>
            </div>
          </div>
        </div> */}
      </LayoutOne>
    </Fragment>
  );
};

export default ProductsByTag;
