import PropTypes from "prop-types";
import React, { Fragment } from "react";
import HeaderOne from "../wrappers/header/HeaderOne";
import FooterOne from "../wrappers/footer/LandingFooter";
import FooterContainer from "../components/reworkedComponents/reworkedFooter/FooterContainer";
import ReworkedHeaderContainer from "../components/reworkedComponents/reworkedHeader/ReworkedHeaderContainer";

const LayoutOne = ({
  children,
  headerContainerClass,
  headerTop,
  headerPaddingClass,
  headerPositionClass,
  search_bar_change,
}) => {
  return (
    <Fragment>
      {/* <HeaderOne
        layout={headerContainerClass}
        top={headerTop}
        headerPaddingClass={headerPaddingClass}
        headerPositionClass={headerPositionClass}
        search_bar_change={search_bar_change}
      /> */}
      <ReworkedHeaderContainer
        layout={headerContainerClass}
        top={headerTop}
        headerPaddingClass={headerPaddingClass}
        headerPositionClass={headerPositionClass}
        search_bar_change={search_bar_change}
      />

      {children}
      <FooterContainer />

      {/* <FooterOne
        backgroundColorClass="bg-gray"
        spaceTopClass="pt-100"
        spaceBottomClass="pb-70"
      /> */}
    </Fragment>
  );
};

LayoutOne.propTypes = {
  children: PropTypes.any,
  headerContainerClass: PropTypes.string,
  headerPaddingClass: PropTypes.string,
  headerPositionClass: PropTypes.string,
  headerTop: PropTypes.string,
};

export default LayoutOne;
