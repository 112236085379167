import React, { useEffect, useState } from "react";
import "./headerStyling.css";
import brandImg from "../../../assets/brandImg.png";
const subCategoryHeadings = [
  "Details",
  "Feedback  113",
  "Shipping & Payment",
  "Information",
];
const brandDetailsHeadings = [
  "Brand",
  "Model",
  "Item Weight",
  "Product Dimensions",
  "Origin",
  "Item model number",
];
const brandDetails = [
  "Fuel Helmets",
  "SH-FF0016",
  "4.5 pounds",
  "10.6 x 13.8 x 10.4 inches",
  "China",
  "SH-FF0016",
];

const HoverMenu = ({ setIsShowing, selected, brandImage, noticebar }) => {
  const [selectedCategory, setSelectedCategory] = useState(0);
  const [secondaryCategory, setSecondaryCategory] = useState([]);
  const [secondaryCategoryName, setSecondaryCategoryName] = useState("");

  useEffect(() => {
    setSecondaryCategory([]);
    console.log({ selected });
  }, [selected]);
  useEffect(() => {
    console.log({ secondaryCategory });
  }, [secondaryCategory]);
  return (
    <div
      className="mega-menu"
      style={{
        top: noticebar ? '115px' : '90px'
      }}
      onMouseLeave={() => {
        setIsShowing(false);
      }}
    >
      <div className="sub-category-headings">
        {selected?.sub_categories?.map((e, index) => (
          <div
            key={index}
            style={{
              width: "100%",
              display: "flex",
              justifyContent: "space-between",
            }}
          >
            <a
              style={{
                fontSize: 14,
                fontWeight: 500,
                margin: 0,
                color: index === selectedCategory ? "white" : "#B6B6B6",
                cursor: "pointer",
                marginBottom: 20,
              }}
              href={`/category/${selected?.name}/${e?.name}`}
              onMouseEnter={() => {
                setSelectedCategory(index);
                setSecondaryCategory(e?.secondry_sub_categories);
                setSecondaryCategoryName(e.name);
              }}
            >
              {e.name}
            </a>
            {index === selectedCategory && (
              <div
                style={{
                  width: 2,
                  height: 30,
                  backgroundColor: "#FFFFFF",
                  position: "absolute",
                  right: -2,
                }}
              ></div>
            )}
          </div>
        ))}
      </div>

      <div className="brand-details-headings">
        {secondaryCategory?.map((e, index) => (
          <a
            style={{
              fontSize: 14,
              fontWeight: 500,
              margin: 0,
              color: "white",
              cursor: "pointer",
              marginBottom: 10,
            }}
            key={index}
            href={`/category/${selected?.name}/${secondaryCategoryName}/${e?.name}`}
          >
            {e?.name}
          </a>
        ))}
      </div>
      {/* <div className="brand-details-headings">
        {brandDetails.map((e, index) => (
          <p
            style={{
              fontSize: 14,
              fontWeight: 500,
              margin: 0,
              color: "white",
              cursor: "pointer",
              marginBottom: 10,
            }}
            key={index}
          >
            {e}
          </p>
        ))}
      </div> */}
      <div className="brand-details-headings">
        <img
          src={brandImage}
          alt=""
          style={{ objectFit: "cover", width: 130, height: 115 }}
        />
        <p
          style={{
            fontSize: 12,
            fontWeight: 400,
            margin: 0,
            color: "white",
          }}
        >
          Stock New Brands in <br />
          {selected?.name}
        </p>
      </div>
    </div>
  );
};

export default HoverMenu;
