import React from "react";
import { useHistory } from "react-router-dom";
import "./shopBy.css";
const ShopByCategoryCards = ({ image, text }) => {
  const history = useHistory();

  return (
    <div
      style={{
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        justifyContent: "center",
        margin: 20,
        cursor: "pointer",
      }}
      onClick={() => {
        history.push(`/category/${text}/`);
      }}
    >
      <img
        src={image}
        alt=""
        style={{ height: 370, width: 275, objectFit: "cover" }}
      />
      <p
        style={{
          color: "black",
          fontSize: 18,
          marginBottom: 10,
          marginTop: 10,
        }}
      >
        {text}
      </p>
    </div>
  );
};

export default ShopByCategoryCards;
