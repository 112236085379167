import PropTypes from "prop-types";
import React, { Fragment, useState, useEffect } from "react";
import Swiper from "react-id-swiper";
import { getProductCartQuantity } from "../../helpers/product";
import { Modal } from "react-bootstrap";
import Rating from "./sub-components/ProductRating";
import { connect } from "react-redux";
import { Form, Row, Col, Button } from "react-bootstrap";
import { Link } from 'react-router-dom';
import { addToCart } from '../../redux/actions/cartActions';
import { addToCompare } from '../../redux/actions/compareActions';
import { addToWishlist } from '../../redux/actions/wishlistActions';
import { useHistory } from "react-router";

function ProductModal(props) {
  const { product } = props;
  const { currency } = props;
  const { discountedprice } = props;
  const { finalproductprice } = props;
  const { finaldiscountedprice } = props;
  const { getUser } = props;

  const history = useHistory();

  const [gallerySwiper, getGallerySwiper] = useState(null);
  const [thumbnailSwiper, getThumbnailSwiper] = useState(null);
  const [selectedProductColor, setSelectedProductColor] = useState(
    product.variation ? product.variation[0].color : ""
  );
  const [selectedProductSize, setSelectedProductSize] = useState(
    product.variation ? product.variation[0].size[0].name : ""
  );
  const [productStock, setProductStock] = useState(
    product.variation ? product.variation[0].size[0].stock : product.stock
  );
  const [quantityCount, setQuantityCount] = useState(1);
  const [selectedProductVariant, setSelectedProductVariant] = useState([]);
  const [isPackSelected, setPackSelected] = useState(false);

  const wishlistItem = props.wishlistitem;
  const compareItem = props.compareitem;

  const addToCart = props.addToCart;
  const addToWishlist = props.addtowishlist;
  const addToCompare = props.addtocompare;

  const addToast = props.addtoast;
  const cartItems = props.cartitems;

  const productCartQty = getProductCartQuantity(
    cartItems,
    product,
    selectedProductColor,
    selectedProductSize
  );




  useEffect(() => {
    let selectedVariant = [];
    if (product.product_varients.length) {
      product.product_varients.forEach(elem => {
        selectedVariant.push({ id: elem.id, type: elem.type, option: JSON.parse(elem.options)[0] });
      });
      setSelectedProductVariant(selectedVariant);
    }
    if (product.pack_size && product.pack_price) {
      setPackSelected(true);
    }
  }, []);

  useEffect(() => {
    if (
      gallerySwiper !== null &&
      gallerySwiper.controller &&
      thumbnailSwiper !== null &&
      thumbnailSwiper.controller
    ) {
      gallerySwiper.controller.control = thumbnailSwiper;
      thumbnailSwiper.controller.control = gallerySwiper;
    }
  }, [gallerySwiper, thumbnailSwiper]);

  const gallerySwiperParams = {
    getSwiper: getGallerySwiper,
    spaceBetween: 10,
    loopedSlides: 4,
    loop: true,
  };

  const thumbnailSwiperParams = {
    getSwiper: getThumbnailSwiper,
    spaceBetween: 10,
    slidesPerView: 4,
    loopedSlides: 4,
    touchRatio: 0.2,
    freeMode: true,
    loop: true,
    slideToClickedSlide: true,
    navigation: {
      nextEl: ".swiper-button-next",
      prevEl: ".swiper-button-prev",
    },
    renderPrevButton: () => (
      <button className="swiper-button-prev ht-swiper-button-nav">
        <i className="pe-7s-angle-left" />
      </button>
    ),
    renderNextButton: () => (
      <button className="swiper-button-next ht-swiper-button-nav">
        <i className="pe-7s-angle-right" />
      </button>
    ),
  };

  const handleSelectChange = async (id, type, value) => {
    let variant = JSON.parse(JSON.stringify(selectedProductVariant));
    variant.forEach(elem => {
      if (elem.id == id && elem.type == type) {
        elem.option = value
      }
    });
    await setSelectedProductVariant(variant);
    console.log(variant);
  }

  return (
    <Fragment>
      <Modal
        show={props.show}
        onHide={props.onHide}
        className="product-quickview-modal-wrapper"
      >
        <Modal.Header closeButton></Modal.Header>

        <div className="modal-body">
          <div className="row">
            <div className="col-md-5 col-sm-12 col-xs-12">
              <div className="product-large-image-wrapper">
                <Swiper {...gallerySwiperParams}>
                  {JSON.parse(product.photo) &&
                    JSON.parse(product.photo).map((single, key) => {
                      return (
                        <div key={key}>
                          <div className="single-image">
                            <img
                              src={`${single}`}
                              className="img-fluid"
                              alt=""
                            />
                          </div>
                        </div>
                      );
                    })}
                </Swiper>
              </div>
              <div className="product-small-image-wrapper mt-15">
                <Swiper {...thumbnailSwiperParams}>
                  {JSON.parse(product.photo) &&
                    JSON.parse(product.photo).map((single, key) => {
                      return (
                        <div key={key}>
                          <div className="single-image">
                            <img
                              src={`${single}`}
                              className="img-fluid"
                              alt=""
                            />
                          </div>
                        </div>
                      );
                    })}
                </Swiper>
              </div>
            </div>
            <div className="col-md-7 col-sm-12 col-xs-12">
              <div className="product-details-content quickview-content">
                <h2>{product.name}</h2>
                { getUser && Object.keys(getUser).length > 0 ? 
                    <div className="product-details-price">
                      {discountedprice !== null ? (
                        <Fragment>
                          <span>Wholesale</span>
                          <span className="amount old" style={{ marginLeft: 10 }}>
                            {currency.currencySymbol + finalproductprice.toFixed(2)}
                          </span>
                          <span style={{ marginLeft: 10 }}>{currency.currencySymbol + finaldiscountedprice.toFixed(2)}</span>{" "}
                          <span style={{ color: '#000', marginLeft: 10 }}> | Retail {currency.currencySymbol + product.retail_price.toFixed(2)} </span>
                        </Fragment>
                      ) : (
                        <span>Wholesale {currency.currencySymbol + finalproductprice.toFixed(2)} <span style={{ color: '#000' }}> | Retail {currency.currencySymbol + product.retail_price.toFixed(2)}</span></span>
                      )}
                    </div>
                  :
                    <div className="product-details-price">
                      <Fragment>
                        <span style={{ cursor: 'pointer' }} onClick={(e) => { e.preventDefault(); history.push('/buyer/login'); }}>
                          {`Signup to see prices  `}  <i className="fa fa-sign-in" />
                        </span>
                      </Fragment>
                    </div>
                } 
                { 
                  product.price_note && product.price_note.length > 0 ? 
                    <div className="pro-details-list">
                      <p>{product.price_note}</p>
                    </div> 
                    : 
                    null
                }
                {/* {product.rating && product.rating > 0 ? (
                  <div className="pro-details-rating-wrap">
                    <div className="pro-details-rating">
                      <Rating ratingValue={product.rating} />
                    </div>
                  </div>
                ) : (
                  ""
                )} */}
                <div className="pro-details-list">
                  <p>{product.description}</p>
                </div>
                { product.pack_size && product.pack_size && 
                  <div className="pr-10">
                    <h2 className="mb-3">Sold in Packs</h2>
                    <div>
                      <span className="size-name">Pack of {`${product.pack_size}`}</span><br />
                      {
                        getUser && Object.keys(getUser).length > 0 ? 
                            <span className="size-name">Pack price {currency.currencySymbol + product.pack_price.toFixed(2)}</span>
                          :
                            <span className="size-name" style={{ cursor: 'pointer' }} onClick={(e) => { e.preventDefault(); history.push('/buyer/login'); }}>{`Signup to see prices  `}  <i className="fa fa-sign-in" /></span>
                      }
                    </div>
                  </div>
                }
                {
                  product.product_varients && product.product_varients.length ? 
                    <h2 className="mb-3 mt-3"> Variants </h2>
                  : null
                }
                {
                  product.product_varients ? (
                    product.product_varients.map((single, key) => {
                        return (
                          <div className="pro-details-size-color" style={{ borderBottom: 'none' }} key={key}>
                            <div className="pro-details-size" style={{ width: '100%' }}>
                              <div className="pro-details-size-content">
                                <Row>
                                  <Col>
                                    <Form.Group controlId="exampleForm.ControlSelect1">
                                      <Form.Label className='up-f'>{ single.type }</Form.Label>
                                      <Form.Control as="select" onChange={(e) => handleSelectChange(single.id, single.type, e.target.value)}>
                                      {
                                        JSON.parse(single.options).map((option, i) => (
                                          <option value={option} key={i}>{option}</option>
                                        ))
                                      }
                                      </Form.Control>
                                    </Form.Group>
                                  </Col>
                                </Row>
                                {/* {
                                  JSON.parse(single.options).map((option, i) => (
                                    <label className={`pro-details-size-content--single`}  key={i} >
                                      <input
                                        type="radio"
                                        value={option}
                                        checked = {option ===  selectedProductSize ? "checked" : "" }
                                      />
                                      <span className="size-name"> { option } </span>
                                    </label>
                                  ))
                                } */}
                              </div>
                            </div>
                          </div>
                      )
                    })
                  ) : ("")
                }
                {/* { product.product_varients ? (
                  <div className="pro-details-size-color">
                    <div className="pro-details-color-wrap">
                      <span>Color</span>
                      <div className="pro-details-color-content">
                        {product.variation.map((single, key) => {
                          return (
                            <label
                              className={`pro-details-color-content--single ${single.color}`}
                              key={key}
                            >
                              <input
                                type="radio"
                                value={single.color}
                                name="product-color"
                                checked={
                                  single.color === selectedProductColor
                                    ? "checked"
                                    : ""
                                }
                                onChange={() => {
                                  setSelectedProductColor(single.color);
                                  setSelectedProductSize(single.size[0].name);
                                  setProductStock(single.size[0].stock);
                                  setQuantityCount(1);
                                }}
                              />
                              <span className="checkmark"></span>
                            </label>
                          );
                        })}
                      </div>
                    </div>
                    <div className="pro-details-size">
                      <span>Size</span>
                      <div className="pro-details-size-content">
                        {product.variation &&
                          product.variation.map(single => {
                            return single.color === selectedProductColor
                              ? single.size.map((singleSize, key) => {
                                  return (
                                    <label
                                      className={`pro-details-size-content--single`}
                                      key={key}
                                    >
                                      <input
                                        type="radio"
                                        value={singleSize.name}
                                        checked={
                                          singleSize.name ===
                                          selectedProductSize
                                            ? "checked"
                                            : ""
                                        }
                                        onChange={() => {
                                          setSelectedProductSize(
                                            singleSize.name
                                          );
                                          setProductStock(singleSize.stock);
                                          setQuantityCount(1);
                                        }}
                                      />
                                      <span className="size-name">
                                        {singleSize.name}
                                      </span>
                                    </label>
                                  );
                                })
                              : "";
                          })}
                      </div>
                    </div>
                  </div>
                ) : (
                  ""
                )} */}
                {product.affiliateLink ? (
                  <div className="pro-details-quality">
                    <div className="pro-details-cart btn-hover">
                      <a
                        href={product.affiliateLink}
                        rel="noopener noreferrer"
                        target="_blank"
                      >
                        Buy Now
                      </a>
                    </div>
                  </div>
                ) : (
                  <div className="pro-details-quality">
                    <div className="product-details-price">
                      <span style={{ color: '#000', marginRight: 10 }}>Quantity</span>
                    </div>
                    <div className="cart-plus-minus">
                      <button
                        onClick={() =>
                          setQuantityCount(
                            quantityCount > 1 ? quantityCount - 1 : 1
                          )
                        }
                        className="dec qtybutton"
                      >
                        -
                      </button>
                      <input
                        className="cart-plus-minus-box"
                        type="text"
                        value={quantityCount}
                        readOnly
                      />
                      <button
                        onClick={() =>
                          setQuantityCount(
                            quantityCount < product.quantity - productCartQty
                              ? quantityCount + 1
                              : quantityCount
                          )
                        }
                        className="inc qtybutton"
                      >
                        +
                      </button>
                    </div>
                    <div className="pro-details-cart btn-hover">
                      {!product.out_of_stock &&
                      product.quantity &&
                      product.quantity > 0 ? (
                        <button
                          onClick={() =>
                            addToCart(
                              product,
                              addToast,
                              quantityCount,
                              selectedProductColor,
                              selectedProductSize,
                              selectedProductVariant,
                              isPackSelected
                            )
                          }
                          disabled={productCartQty >= product.quantity}
                        >
                          {" "}
                          Add To Cart{" "}
                        </button>
                      ) : (
                        <button disabled>Out of Stock</button>
                      )}
                    </div>
                    <div className="pro-details-wishlist">
                      <button
                        className={wishlistItem !== undefined ? "active" : ""}
                        disabled={wishlistItem !== undefined}
                        title={
                          wishlistItem !== undefined
                            ? "Added to wishlist"
                            : "Add to wishlist"
                        }
                        onClick={() => addToWishlist(product, addToast)}
                      >
                        <i className="pe-7s-like" />
                      </button>
                    </div>
                    <div className="pro-details-compare">
                      <button
                        className={compareItem !== undefined ? "active" : ""}
                        disabled={compareItem !== undefined}
                        title={
                          compareItem !== undefined
                            ? "Added to compare"
                            : "Add to compare"
                        }
                        onClick={() => addToCompare(product, addToast)}
                      >
                        <i className="pe-7s-shuffle" />
                      </button>
                    </div>
                  </div>
                )}
                <div style={{ textAlign: 'center' }}>
                  <Link style={{ textDecoration: 'underline' }} to={process.env.PUBLIC_URL + `/product/${product.id}`}>
                    View More Details
                  </Link>
                </div>
              </div>
            </div>
          </div>
        </div>
      </Modal>
    </Fragment>
  );
}

ProductModal.propTypes = {
  addtoast: PropTypes.func,
  addtocart: PropTypes.func,
  addtocompare: PropTypes.func,
  addtowishlist: PropTypes.func,
  cartitems: PropTypes.array,
  compareitem: PropTypes.object,
  currency: PropTypes.object,
  discountedprice: PropTypes.number,
  finaldiscountedprice: PropTypes.number,
  finalproductprice: PropTypes.number,
  onHide: PropTypes.func,
  product: PropTypes.object,
  show: PropTypes.bool,
  wishlistitem: PropTypes.object,
};


const mapDispatchToProps = dispatch => {
  return {
    addToCart: (
      item,
      addToast,
      quantityCount,
      selectedProductColor,
      selectedProductSize,
      selectedProductVariant,
      isPackSelected
    ) => {
      dispatch(
        addToCart(
          item,
          addToast,
          quantityCount,
          selectedProductColor,
          selectedProductSize,
          selectedProductVariant,
          isPackSelected
        )
      );
    },
    addToWishlist: (item, addToast) => {
      dispatch(addToWishlist(item, addToast));
    },
    addToCompare: (item, addToast) => {
      dispatch(addToCompare(item, addToast));
    }
  };
};

const mapStateToProps = (state) => {
  return {
    cartitems: state.cartData,
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(ProductModal);
