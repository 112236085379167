import BeautyWellness from '../assets/img/micheile-dot-com-auKN3JcMKso-unsplash.jpg';
import WomenClothing from '../assets/img/women_clothing.jpg';
import MensClothing from '../assets/img/mens_clothing.jpg';
import Accessories from '../assets/img/accessories.jpg';
import FoodDrinks from '../assets/img/food_drinks.jpg';
import HomeDecor from '../assets/img/home_decor.jpg';

import MadeInSA from '../assets/img/made_in_sa.jpg';
import EcoFriendly from '../assets/img/ecofriendly.jpg';
import SmallBatch from '../assets/img/small_bacth.jpg';
import NotSoldOnAmazon from '../assets/img/not_sold_on_amazon.jpg';
import WomenOnly from '../assets/img/women_only.jpg';
import Organic from '../assets/img/organic.jpg';
import SocialGood from '../assets/img/social_good.jpg';
import NoConsignment from '../assets/img/no_consignment.jpg';

export const dummyData = [
  {
    title: "OPTICAL",
    subtitle: "By P.F. Candle Co.",
    image:
      "https://images.pexels.com/photos/1704488/pexels-photo-1704488.jpeg?auto=compress&cs=tinysrgb&dpr=1&w=500",
  },
  {
    title: "SUN",
    subtitle: "By P.F. Candle Co.",
    image:
      "https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcSy2L0a9WcIvDDRnFujHMGOuPHc4Fwz02YExA&usqp=CAU",
  },
  {
    title: "ACCESSORIES",
    subtitle: "By P.F. Candle Co.",
    image:
      "https://i.pinimg.com/originals/79/b1/5e/79b15e713b798e94d4a3b099030c1515.jpg",
  },
  {
    title: "OPTICAL 2",
    subtitle: "By P.F. Candle Co.",
    image:
      "https://images.pexels.com/photos/1704488/pexels-photo-1704488.jpeg?auto=compress&cs=tinysrgb&dpr=1&w=500",
  },
  {
    title: "OPTICAL 3",
    subtitle: "By P.F. Candle Co.",
    image:
      "https://i.pinimg.com/originals/79/b1/5e/79b15e713b798e94d4a3b099030c1515.jpg",
  },
  {
    title: "OPTICAL 2",
    subtitle: "By P.F. Candle Co.",
    image:
      "https://images.pexels.com/photos/1704488/pexels-photo-1704488.jpeg?auto=compress&cs=tinysrgb&dpr=1&w=500",
  },
  {
    title: "OPTICAL 3",
    subtitle: "By P.F. Candle Co.",
    image:
      "https://i.pinimg.com/originals/79/b1/5e/79b15e713b798e94d4a3b099030c1515.jpg",
  },
];

export const dummyDataShopByValues = [
  {
    title: 'Eco Friendly',
    val: 'Eco-friendly',
    image: EcoFriendly
  },
  {
    title: 'Women Owned',
    val: 'Women Owned',
    image: MadeInSA
  },
  {
    title: 'Ethically produced',
    val: 'Ethically produced',
    image: SmallBatch
  },
  {
    title: 'Organic',
    val: 'Organic',
    image: NotSoldOnAmazon
  },
  {
    title: 'Small Batch',
    val: 'Small Batch',
    image:  SocialGood
  },
  {
    title: 'Social Responsibility',
    val: 'Social Responsibility',
    image: Organic
  },
  {
    title: 'Handmade',
    val: 'Handmade',
    image:  WomenOnly
  },
  {
    title: 'Vegan Friendly',
    val: 'Vegan Friendly',
    image: NoConsignment
  },
]

export const dummyDataShopBy = [
  {
    title: "FoodDrinks",
    image: FoodDrinks,
  },
  {
    title: "Beauty & Wellness",
    image: BeautyWellness,
  },
  {
    title: "Women Fashion",
    image: WomenClothing,
  },
  {
    title: "Mens Fashion",
    image: MensClothing,
  },
  // {
  //   title: "Accessories",
  //   image: Accessories,
  // },
  {
    title: "HomeDecor",
    image: HomeDecor,
  },
  {
    title: "Made in South Africa",
    image:
      "../assets/img/home_decor.jpg",
  },
  {
    title: "Eco-friendly",
    image:
      "../assets/img/home_decor.jpg",
  },
];
export const dummyDataReviews = [
  {
    title: "Amber",
    tagline: "Brand Coordinator",
    video: "87I8bLLohb0",
  },
  {
    title: "Eli",
    tagline: "Founder",
    video: "riiCpTVAEWc",
  },
  {
    title: "Murray",
    tagline: "Marketing director",
    video: "IizquZIF2qI",
  },
];
export const pageLinks = [
  {
    text: "Home",
    linkTo: "/home",
  },
  {
    text: "About Us",
    linkTo: "/about",
  },
  {
    text: "Brands",
    linkTo: "/home",
  },
  {
    text: "Contact",
    linkTo: "/contact",
  },
];
export const supportLinks = [
  {
    text: "FAQ",
    linkTo: "/faq",
  },
  // {
  //   text: "Live Chat",
  //   linkTo: "/seller/apply",
  // },
  // {
  //   text: "Term of Service",
  //   linkTo: "/seller/apply",
  // },
  // {
  //   text: "Return Policy",
  //   linkTo: "/seller/apply",
  // },
];
export const usefulLinks = [
  {
    text: "Apply as a Brand",
    linkTo: "/seller/apply",
  },
  {
    text: "Registered Brand? Signin",
    linkTo: "/seller/login",
  },
  {
    text: "Signup as a Stockist",
    linkTo: "/buyer/register",
  },
  {
    text: "Registered Stockist? Signin",
    linkTo: "/buyer/login",
  },
  {
    text: "Signin as Assignee",
    linkTo: "/assignee/login",
  },
];
