import PropTypes from "prop-types";
import React, { useState } from "react";
import { Dropdown, Form } from "react-bootstrap";
import { setActiveSort } from "../../helpers/product";

const ShopCategories = ({ categories, getSortParams }) => {
  const [selectedCategoryId, setSelectedCategoryId] = useState(-1);
  const [subCategories, setSubCategories] = useState([]);
  const [secSubCategories, setSecSubCategories] = useState([]);
  const [selectedSubCategoryId, setSelectedSubCategoryId] = useState(-1);
  const [selectedSecSubCategoryId, setSelectedSecSubCategoryId] = useState(-1);

  return (
    <div className="sidebar-widget">
      <Dropdown>
        <Dropdown.Toggle
          id="dropdown-basic"
          style={{
            backgroundColor: "#ededed",
            borderRadius: 50,
            color: "black",
            fontSize: 17,
            fontFamily: "lato",
            border: "none",
            margin: 10,
          }}
        >
          Categories
        </Dropdown.Toggle>

        <Dropdown.Menu
          style={{
            backgroundColor: "white",
            borderRadius: 20,
            padding: 20,
          }}
        >
          <div
            style={{
              display: "flex",
              width: "100%",
              alignItems: "center",
              justifyContent: "flex-start",
              cursor: "pointer",
            }}
            onClick={(e) => {
              getSortParams("category", "");
              // setActiveSort(e);
              setSelectedCategoryId(-1);
              setSubCategories([]);
              setSecSubCategories([]);
              setSelectedSubCategoryId(-1);
              setSelectedSecSubCategoryId(-1);
            }}
          >
            <input
              type="checkbox"
              className="custom-checkbox-shop"
              checked={selectedCategoryId == -1 ? true : false}
            />
            <p
              style={{
                color: "#343538",
                fontSize: 14,
                fontFamily: "lato",
                marginBottom: 0,
                marginLeft: 10,
              }}
            >
              All Categories
            </p>
          </div>

          {categories.map((category, index) => (
            <div
              key={index}
              style={{
                display: "flex",
                width: "100%",
                alignItems: "center",
                justifyContent: "flex-start",
                cursor: "pointer",
                minWidth: 150,
              }}
              onClick={(e) => {
                getSortParams("category", category.id);
                // setActiveSort(e);
                setSelectedCategoryId(category.id);
                setSubCategories(category.sub_categories);
                setSecSubCategories([]);
                setSelectedSubCategoryId(-1);
                setSelectedSecSubCategoryId(-1);
              }}
            >
              <input
                type="checkbox"
                className="custom-checkbox-shop"
                checked={selectedCategoryId == category.id ? true : false}
                id="cBOx"
              />
              <p
                style={{
                  color: "#343538",
                  fontSize: 14,
                  fontFamily: "lato",
                  marginBottom: 0,
                  marginLeft: 10,
                }}
              >
                {category.name}
              </p>
            </div>
          ))}
        </Dropdown.Menu>
      </Dropdown>

      {/* {selectedCategoryId > 0 ? (
        <h4 className="mt-3 pro-sidebar-title">Sub Categories</h4>
      ) : null}
      {selectedCategoryId > 0 ? (
        <div className="sidebar-widget-list mt-30">
          {subCategories && subCategories.length > 0 ? (
            <ul>
              {subCategories.map((category, key) => {
                return (
                  <li key={key}>
                    <div className="sidebar-widget-list-left">
                      <button
                        className={
                          selectedSubCategoryId == category.id ? "active" : ""
                        }
                        onClick={(e) => {
                          getSortParams("sub_category", {
                            cat: selectedCategoryId,
                            subCat: category.id,
                          });
                          // setActiveSort(e);
                          setSecSubCategories(category.secondry_sub_categories);
                          setSelectedSubCategoryId(category.id);
                          setSelectedSecSubCategoryId(-1);
                        }}
                      >
                        {" "}
                        <span className="checkmark" /> {category.name}{" "}
                      </button>
                    </div>
                  </li>
                );
              })}
            </ul>
          ) : (
            "No Sub Categories found"
          )}
        </div>
      ) : null}
      {selectedCategoryId > 0 && selectedSubCategoryId > 0 ? (
        <h4 className="mt-3 pro-sidebar-title">Child Sub Categories</h4>
      ) : null}
      {selectedCategoryId > 0 && selectedSubCategoryId > 0 ? (
        <div className="sidebar-widget-list mt-30">
          {secSubCategories && secSubCategories.length > 0 ? (
            <ul>
              {secSubCategories.map((category, key) => {
                return (
                  <li key={key}>
                    <div className="sidebar-widget-list-left">
                      <button
                        className={
                          selectedSecSubCategoryId == category.id
                            ? "active"
                            : ""
                        }
                        onClick={(e) => {
                          getSortParams("sec_sub_category", {
                            cat: selectedCategoryId,
                            subCat: selectedSubCategoryId,
                            secSubCat: category.id,
                          });
                          setSelectedSecSubCategoryId(category.id);
                        }}
                      >
                        {" "}
                        <span className="checkmark" /> {category.name}{" "}
                      </button>
                    </div>
                  </li>
                );
              })}
            </ul>
          ) : (
            "No Child Sub Categories found"
          )}
        </div>
      ) : null} */}
    </div>
  );
};

ShopCategories.propTypes = {
  categories: PropTypes.array,
  getSortParams: PropTypes.func,
};

export default ShopCategories;
