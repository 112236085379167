import React from 'react';
import Skeleton, { SkeletonTheme } from 'react-loading-skeleton';
import './Skelton.css';

const SkeltonCard = () => { 
    return (
        <div className='product-wrap product-container ml-0 mr-0'>
            <div className='mb-2 skeltonImageContainer'>
                <Skeleton height={325} />
            </div>
            <div style={{ width: '100%' }}>
                <Skeleton className='mb-2' height={20} />
                <Skeleton className='mb-2' height={20} />
                <Skeleton className='mb-2' height={20} />
            </div>
        </div>
    )
}

export default SkeltonCard;