import React, { useEffect, useState } from "react";
import { Accordion, Button, Card } from "react-bootstrap";
import { AiOutlineRight } from "react-icons/ai";
import { AiOutlineDown } from "react-icons/ai";
import { useHistory } from "react-router-dom";
import "./headerStyling.css";
import MobileMenuSubAccordion from "./MobileMenuSubAccordion";

const MobileMenuAccordian = ({
  elem,
  i,
  currentEventKeyCats,
  setCurrentEventKeyCats,
  setShowCollapseMenu,
}) => {
  const [currentEventKeySubCats, setCurrentEventKeySubCats] = useState(null);

  const history = useHistory();

  return (
    <Card
      key={i}
      className="text-left"
      style={{ backgroundColor: "transparent" }}
    >
      <Card.Header
        style={{
          display: "flex",
          alignItems: "center",
          justifyContent: "space-between",
          //   backgroundColor: "rgba(0, 0, 0, 0.88)",
          //   backdropFilter: " blur(5px)",
        }}
      >
        <p
          className="collapse-item"
          onClick={() => {
            history.push(`/category/${elem.name}/`);
            setShowCollapseMenu(false);
          }}
        >
          {elem.name}
        </p>
        <Accordion.Toggle
          as={Button}
          eventKey={i}
          onClick={() => {
            if (currentEventKeyCats === i) {
              setCurrentEventKeyCats(null);
            } else {
              setCurrentEventKeyCats(i);
            }
          }}
          style={{ background: "none", border: "none" }}
        >
          {currentEventKeyCats === i ? (
            <AiOutlineDown
              color="white"
              size={20}
              style={{ cursor: "pointer", zIndex: 200 }}
              onClick={() => console.log({ elem })}
            />
          ) : (
            <AiOutlineRight
              color="white"
              size={20}
              style={{ cursor: "pointer", zIndex: 200 }}
              onClick={() => console.log({ elem })}
            />
          )}
        </Accordion.Toggle>
      </Card.Header>
      {elem?.sub_categories?.map((e, index) => (
        <Accordion.Collapse eventKey={i} key={index}>
          <Accordion style={{ width: "100%" }}>
            <MobileMenuSubAccordion
              mainCategory={elem.name}
              elem={e}
              i={index}
              currentEventKeySubCats={currentEventKeySubCats}
              setCurrentEventKeySubCats={setCurrentEventKeySubCats}
              setShowCollapseMenu={setShowCollapseMenu}
            />
          </Accordion>
        </Accordion.Collapse>
      ))}
    </Card>
  );
};

export default MobileMenuAccordian;
