import Axios from "axios";
import React, { useEffect, useState } from "react";
import { Dropdown, NavDropdown } from "react-bootstrap";
import { Link, useHistory } from "react-router-dom";
import config from "../../../config";
import { useWindowDims } from "../../../helpers/useDimensions";
import "./headerStyling.css";
import WomenClothing from '../../../assets/img/m_women_clothing.jpg';
import MensClothing from '../../../assets/img/m_mens_clothing.jpg';
import Accessories from '../../../assets/img/MENU_ACCESSORIES.jpg';
import BeautyWellness from '../../../assets/img/m_beauty_wellness.jpg';
import FoodDrinks from '../../../assets/img/MENU_FOOD_DRINK.jpg';
import HomeDecor from '../../../assets/img/m_home_decor.jpg';

const HoverLinks = ({
  cat,
  setSubCategories,
  setIsShowing,
  setSelectedCategory,
  setShowHoverCat,
  setBrandImage
}) => {
  const [showDropDown, setShowDropDown] = useState(false);
  const { width } = useWindowDims();
  const history = useHistory();
  const handleHoverCat = (cat, subCat, secSubCat) => {
    let obj = {
      cat: cat,
      subCat: subCat,
      secSubCat: secSubCat,
    };
    setShowHoverCat(obj);
  };
  return (
    <Dropdown
      show={showDropDown}
      onMouseEnter={() => {
        setShowHoverCat({
          cat: cat,
          subCat: cat.sub_categories[0],
          secSubCat:
            cat.sub_categories.length > 0 &&
            cat.sub_categories[0].secondry_sub_categories.length > 0
              ? cat.sub_categories[0].secondry_sub_categories
              : [],
        });
        setShowDropDown(true);
        setSubCategories(cat.sub_categories);
        setIsShowing(true);
        setSelectedCategory(cat);
        if (cat.id == 6) {
          setBrandImage(HomeDecor);
        } else if (cat.id == 5) {
          setBrandImage(FoodDrinks);
        } else if (cat.id == 4) {
          setBrandImage(Accessories);
        } else if (cat.id == 3) {
          setBrandImage(MensClothing);
        }  else if (cat.id == 2) {
          setBrandImage(WomenClothing);
        } else {
          setBrandImage(BeautyWellness);
        }
      }}
      onMouseLeave={() => {
        setShowDropDown(false);
        // setSelectedCategory();
      }}
      onClick={() => history.push(`/category/${cat.name}/`)}
    >
      <Dropdown.Toggle
        id="dropdown-basic"
        className="custom-dropdown-toggle"
        style={{
          background: "#ffffff",
          borderRadius: 7,
          color: "#261E23",
          fontSize: 15,
          fontFamily: "lato",
          border: "none",
        }}
      >
        {`${cat.name}`}
      </Dropdown.Toggle>
    </Dropdown>
  );
};

export default HoverLinks;
