import React, { useEffect, useState } from "react";
import { FaSearch } from "react-icons/fa";

const ShopSearch = ({ getSearch, searchPlaceholder, handleShowSearch }) => {
  const [searchValue, setSearchValue] = useState("");
  const [isMobile, setIsMobile] = useState(false);
  const [isIconClicked, setIsIconClicked] = useState(false);

  const searchProducts = async (value) => {
    await setSearchValue(value);
    getSearch("search", value);
  };

  const searchFormSubmit = (e) => {
    e.preventDefault();
    getSearch("search", searchValue);
  };


  useEffect(() => {
    setIsMobile(window.innerWidth < 768);
    window.addEventListener('resize', () => { setIsMobile(window.innerWidth < 768) });
  }, []);

  return (
    <div
      style={!isMobile ? {
        backgroundColor: "#FFFFFF",
        paddingLeft: 10,
        paddingRight: 10,
        border: "0.742338px solid #1D1F23",
        backdropFilter: "blur(13.2875px)",
        borderRadius: 50,
        display: "flex",
        flexDirection: "row",
        alignItems: "center",
        justifyContent: "space-between",
      } : !isIconClicked ? { 
        backgroundColor: "#FFFFFF",
        border: "0.742338px solid #1D1F23",
        backdropFilter: "blur(13.2875px)",
        borderRadius: 50,
        alignItems: "center",
        justifyContent: "center",
        height: 30,
        minWidth: 45
      } : {
        backgroundColor: "#FFFFFF",
        paddingLeft: 10,
        paddingRight: 10,
        border: "0.742338px solid #1D1F23",
        backdropFilter: "blur(13.2875px)",
        borderRadius: 50,
        display: "flex",
        flexDirection: "row",
        alignItems: "center",
        justifyContent: "space-between",
      }}
    >
      {!isMobile ? <input
        type="text"
        value={searchValue}
        onChange={(e) => searchProducts(e.target.value)}
        placeholder={searchPlaceholder || "Search for brands"}
        style={{ background: "none", border: "none" }}
      /> : isIconClicked ? <input
        type="text"
        value={searchValue}
        onChange={(e) => searchProducts(e.target.value)}
        placeholder={searchPlaceholder || "Search for brands"}
        style={{ background: "none", border: "none" }}
      /> : null}
      <FaSearch color="black" size={20} style={{ marginLeft: !isMobile ? 20 : 10 }}
        onClick={(e) => {
          if (isMobile) {
            setIsIconClicked(true);
            handleShowSearch(true);
          }
       }}
      />
      {/* <div className="pro-sidebar-search mb-50 mt-25">
        <form
          className="pro-sidebar-search-form"
          onSubmit={(e) => searchFormSubmit(e)}
        >
          <input
            type="text"
            value={searchValue}
            onChange={(e) => searchProducts(e.target.value)}
            placeholder="Search By Products"
          />
          <button>
            <i
              className="pe-7s-search"
              onClick={(e) => getSearch("search", searchValue)}
            />
          </button>
        </form>
      </div> */}
    </div>
  );
};

export default ShopSearch;
