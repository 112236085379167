import React, { useState } from "react";
import { Dropdown, Form } from "react-bootstrap";

const FilterWidget = ({ categories, getFilterSortParams, title }) => {
  const [selectedCategoryId, setSelectedCategoryId] = useState(-1);
  const [subCategories, setSubCategories] = useState([]);
  const [secSubCategories, setSecSubCategories] = useState([]);
  const [selectedSubCategoryId, setSelectedSubCategoryId] = useState(-1);
  const [selectedSecSubCategoryId, setSelectedSecSubCategoryId] = useState(-1);

  return (
    <Dropdown>
      <Dropdown.Toggle
        id="dropdown-basic"
        style={{
          backgroundColor: "#ededed",
          borderRadius: 50,
          color: "black",
          fontSize: 17,
          fontFamily: "lato",
          border: "none",
          margin: 10,
        }}
      >
        {title}
      </Dropdown.Toggle>

      <Dropdown.Menu
        style={{
          backgroundColor: "white",
          borderRadius: 20,
          padding: 20,
        }}
      >
        {categories.map((category, index) => (
          <div
            key={index}
            style={{
              display: "flex",
              width: "100%",
              alignItems: "center",
              justifyContent: "flex-start",
              cursor: "pointer",
              minWidth: 150,
            }}
            onClick={() => {
              getFilterSortParams("filterSort", category.value);
            }}
          >
            <p
              style={{
                color: "#343538",
                fontSize: 14,
                fontFamily: "lato",
                marginBottom: 0,
                marginLeft: 10,
              }}
            >
              {category.name}
            </p>
          </div>
        ))}
      </Dropdown.Menu>
    </Dropdown>
  );
};

export default FilterWidget;
