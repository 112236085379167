import axios from 'axios';
import config from '../../config';

const initialState = {};

export default function (state = initialState, action) {
  
  switch (action.type) {
    case 'SET_PRODUCTS':
      return {...state, products: action.payload };
    case 'SET_PRODUCTS_TOTAL':
        return {...state, total: action.payload };
    case 'CREATE_HISTORY':
      axios.post(`${config.prod}/api/history/create`, { buyer_id: action.payload.buyer_id, store_id: action.payload.store_id })
        .then().catch();
      return {...state };
    default:
      return state;
  }
}